
import { MeModule } from "@/store/modules/me";
import { ServerMembersModule } from "@/store/modules/serverMembers";
import { ServersModule } from "@/store/modules/servers";
import settingsPages from "@/utils/serverSettingsPages.json";
import { DrawersModule } from "@/store/modules/drawers";
import { permissions } from "@/constants/rolePermissions";

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  computed: {
    currentSettingTab(): any {
      return this.$route.params.tab;
    },
    serverID(): any {
      return this.$route.params.server_id;
    },
    server(): any {
      return ServersModule.servers[this.serverID];
    },
    isAdmin(): any {
      return ServerMembersModule.isAdmin(
        MeModule.user.id || undefined,
        this.serverID
      );
    },
    isCreator(): any {
      return this.server?.creator?.id === MeModule.user.id;
    },
    pages(): any {
      return Object.values(settingsPages).filter((p: any) => {
        const isAdminOrCreator = this.isCreator || this.isAdmin;
        if (p.owner && !this.isCreator) return false;
        if (p.permission && !isAdminOrCreator) {
          return ServerMembersModule.memberHasPermission(
            MeModule.user.id || "",
            this.serverID,
            permissions[p.permission].value
          );
        }
        if (p.admin && !isAdminOrCreator) return false;
        return true;
      });
    },
  },
  methods: {
    changeTab(path: string) {
      DrawersModule.SetLeftDrawer(false);
      this.$router.push({ params: { tab: path } });
    },
  },
});
